import { ComponentChildren } from "preact";
import { useState, useRef } from "preact/hooks";

export type AccordionProps = {
    sectionName: string;
    sectionNameClass: string;
    children: ComponentChildren;
    topLevelClass: string;
    btnClass: string;
}

function Accordion({
    sectionName,
    sectionNameClass = '',
    children,
    topLevelClass = "text-crisp-white",
    btnClass = "flex w-full justify-between border-b-2 border-crisp-white py-5"
}: AccordionProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [sectionHeight, setSectionHeight] = useState("0px");
    const contentRef = useRef<HTMLDivElement>(null);
  
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
        setSectionHeight(isOpen ? "0px" : `${contentRef?.current?.scrollHeight}px`);
    }

    return (
        <div className={topLevelClass} >
            <button
                className={btnClass}
                onClick={toggleAccordion}
            >
                <span className={`${sectionNameClass}`} >{sectionName}</span>
                {isOpen ? (
                    <span className="pl-4" >-</span>
                ):(
                    <span className="pl-4" >+</span>
                )}
            </button>
            <div 
                ref={contentRef}
                style={{ maxHeight: `${sectionHeight}`, transition: 'max-height 0.5s ease' }}
                className="overflow-hidden"
            >
                {children}
            </div>
        </div>
    )
}

export default Accordion;