import {h} from 'preact';
import register from 'preact-custom-element';
import Accordion, { type AccordionProps } from "@/components/Accordion";


register(
    ({ sectionName, children, sectionNameClass, topLevelClass, btnClass }: AccordionProps) => h(Accordion, { sectionName, children, sectionNameClass, topLevelClass, btnClass }),
    'accordion-section',
    ['section-name', 'children', 'section-name-class', 'top-level-class', 'btn-class']
);
